import React,{useState,useRef} from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import cogoToast from 'cogo-toast';
import AXIOS from '../../axios-config';
import ReCAPTCHA from 'react-google-recaptcha';

//components
import SEO from '../components/seo';
import Layout from '../components/layout';
import Spinner from '../components/spinner';
import settings from '../../settings';
import ImageBackground from "../components/imagebackground";

//images
const banner = `${settings.IMAGES_BASE_URL}/images/pga-west/pga-west-banner.jpg`;

const PgaWest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");


 const initialValues = {
    firstname: '',
    lastname: '',
    zipcode: '',
    email: '',
    address: '',
    city: '',
    state: '',
    phone: '',
  };

 const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .required('Please enter your first name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    lastname: Yup.string()
      .required('Please enter your last name')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    address: Yup.string()
      .required('Please enter your address')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    city: Yup.string()
      .required('Please enter your city')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    state: Yup.string()
      .required('Please enter your state')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    zipcode: Yup.string()
      .required("Please enter your zipcode")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    email: Yup.string()
      .email()
      .required('Please enter your email address')
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Your email address is incorrect'),
    phone: Yup.string()
      .required('Please enter your phone number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter a valid phone number'
      ),
    });

  const errorHandling = (setSubmitting) => {
    captchaRef.current.reset();
    setCaptchaToken("");
    setIsLoading(false);
    setSubmitting(false)
    window.grecaptcha.reset();
  }
  
 const handleSubmit = (values, { setSubmitting, resetForm }) => {
  setIsLoading(true)
  setSubmitting(true);
  const headers = {
    'captchaToken': captchaToken
  };

  if (!captchaToken) {
    cogoToast.error("You must verify the capctcha", {
      position: "top-center"
    });
    errorHandling(setSubmitting);
    return
  }
  AXIOS.post('/api/pgawest-form', values ,{headers})
    .then(() => {
      resetForm();
      errorHandling(setSubmitting);
      cogoToast.success('Your form has been submitted successfully', { position: 'top-center' });
    })
    .catch((err) => {
      errorHandling(setSubmitting);
      cogoToast.error("Your form wasn't submitted", { position: 'top-center' });
    }).finally(() => {
      errorHandling(setSubmitting);
    });
  };

    return (
      <Layout>
        <SEO title="BMW Group Events | BMW Performance Driving School" 
          description="Host your next business meeting with us. We offer meeting rooms, delicious catering options, and plenty of thrilling track time behind the wheel of a BMW in Greenville, SC, Palm Springs, CA and Indianapolis, IN." 
          keywords="Team building activities, bmw group events"  
        />
        <ImageBackground
          imageDesktop={banner}
          imageMobile={banner}
          imageAlt="press"
          hideArrow
        />
        <section className="teambuilding">
          <div className="container">
            <div className="teambuilding__header">
              <h2>COLLECT YOUR PGA WEST DISCOUNT</h2>
              <p>
                Please fill out the form below. Our representative will call you to take payment for your golf experience and get the ball rolling for a Tee time reservation.
              </p>
            </div>
          </div>
        </section>

        <section className= 'getstarted'>
          <div className="container">
            <div className="getstarted__content">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, handleSubmit, isSubmitting,setFieldValue,handleChange }) => (
                  <form onSubmit={handleSubmit}>
                      <h3>
                        <span>*All fields are required</span> <br />
                      </h3>
                      <h4>Information</h4>
                    <div className="input">
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="firstname">*First Name</label>
                          <Field
                            type="text"
                            name="firstname"
                            id="firstname"
                            className={touched.firstname && errors.firstname ? 'has-error' : null}
                          />
                          {errors.firstname &&
                          touched.firstname ? <span className="input-feedback">{errors.firstname}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="lastname">*Last Name</label>
                          <Field
                            type="text"
                            name="lastname"
                            id="lastname"
                            className={touched.lastname && errors.lastname ? 'has-error' : null}
                          />
                          {errors.lastname &&
                          touched.lastname ? <span className="input-feedback">{errors.lastname}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="email">*Your Email</label>
                          <Field
                            type="email"
                            name="email"
                            id="email"
                            className={touched.email && errors.email ? 'has-error' : null}
                          />
                          {errors.email && touched.email ? <span className="input-feedback">{errors.email}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="input__group">
                        <label htmlFor="address">*Address</label>
                        <Field
                          type="text"
                          name="address"
                          id="address"
                          className={touched.address && errors.address ? 'has-error' : null}
                        />
                        {errors.address && touched.address ? <span className="input-feedback">{errors.address}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="city">*City</label>
                          <Field type="text" name="city" id="city" className={touched.city && errors.city ? 'has-error' : null} />
                          {errors.city && touched.city && <span className="input-feedback">{errors.city}</span>}
                        </div>
                        <div className="input__group input--sm">
                          <label htmlFor="state">*State</label>
                          <Field
                            type="text"
                            name="state"
                            id="state"
                            className={touched.state && errors.state ? 'has-error' : null}
                          />
                          {errors.state && touched.state ? <span className="input-feedback">{errors.state}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                      <div className="form__group">
                        <div className="input__group">
                          <label htmlFor="zipcode">*Zip Code</label>
                          <Field
                            type="text"
                            name="zipcode"
                            id="zipcode"
                            className={touched.zipcode && errors.zipcode ? 'has-error' : null}
                          />
                          {errors.zipcode && touched.zipcode ? <span className="input-feedback">{errors.zipcode}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                        <div className="input__group">
                          <label htmlFor="phone">*Phone</label>
                          <Field
                            type="tel"
                            name="phone"
                            id="phone"
                            className={touched.phone && errors.phone ? 'has-error' : null}
                          />
                          {errors.phone && touched.phone ? <span className="input-feedback">{errors.phone}</span> : <span className="input-feedback">&nbsp;</span>}
                        </div>
                      </div>
                    </div>
                    <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/><br/>
                    <button className="btn btn-blue" type="submit" aria-label="submit-btn" disabled={isSubmitting}>
                      {isLoading ? <Spinner /> : 'Submit'}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </section>
      </Layout>
    );
}

export default PgaWest;